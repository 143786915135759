import React from 'react';
//import { useTable,useRowSelect } from "react-table";
//import BTable from 'react-bootstrap/Table';
import BButton from 'react-bootstrap/Button';
import axios from 'axios';
import {API_AUTH_DOMAIN} from '../settings.js';
import { getToken } from '../Utils/Common';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';


class TableDashboard extends React.Component {
  constructor(props) {
    super(props);    
    this.state= {selected: []};
    this.handleDelete = this.handleDelete.bind(this);
  }  
  
  handleDelete = () => {
    alert(this.state.selected);
            
    /* let idsToDelete = selectedFlatRows.map((item)=>item.original.id)*/
    const headers = {headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }}    
    
    axios.post(API_AUTH_DOMAIN+this.props.crudEndpoints.unregister, this.state.selected,headers).then(response => {                             
       
        console.log(response);
        this.forceUpdate();
        debugger
        this.props.history.go(0);
        //history.go(0) //Reload page
    }).catch(error => {                  
        console.log(error)
       
    }); 
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.id)
      }));
    }
  }
  handleOnSingleSelect = (row) => {
    this.setState(() => ({
      selected: [row.id]
    }));
    this.props.parentState(row); //Propagate selection to parent
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
      
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
    
  }

  render() {
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };    

    const selectOnlyOneRow = {
      mode: 'radio',    
      clickToSelect: true,
      clickToEdit: true,
      selected: this.state.selected,
      onSelect: this.handleOnSingleSelect
    };

    const crudEndpoints = this.props.crudEndpoints;
    const cellEdit = cellEditFactory({ 
      mode: 'click', 
      blurToSave: true,
      beforeSaveCell(oldValue, newValue, row, column, done) {       
        //If api endpoint to insert/update has been defined
        debugger
        if (crudEndpoints.register){
          //Get the object row edited change field for new value and send to update api
          
          row[column.dataField] = newValue;
          const headers = {headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }}
          
          axios.post(API_AUTH_DOMAIN+crudEndpoints.register,row ,headers).then(response => {      
            
            console.log("Updated succesfully")            
            
          }).catch(error => {      
            console.log("Update ERROR")
          });
          done()
        }
        
        return { async: true };
      },   
     })
    
    return (
      <>
      <BootstrapTable          
        bootstrap4 
        keyField='id' 
        data={ this.props.data } 
        columns={ this.props.columns } 
        pagination={ paginationFactory() } 
        filter={ filterFactory() }
        selectRow={ this.props.rowSelection==="multiple"?selectRow:selectOnlyOneRow}      
        className="table table-dark"        
        cellEdit={ cellEdit }
        rowStyle={ this.props.myStyle }
      >        
      </BootstrapTable>  
      {this.props.crudEndpoints.unregister &&
      <BButton variant="danger" onClick={this.handleDelete} disabled={false}>Delete Selected</BButton> 
      }
      </>
    );
    }
}
  

/* function TableDashboard({ columns, data,endpointUnregister }) {
    const history = useHistory()
    // handle button delete form
    const handleDelete = () => {
        alert(JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          ));
                
        let idsToDelete = selectedFlatRows.map((item)=>item.original.id)
        const headers = {headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }}
        axios.post(API_AUTH_DOMAIN+endpointUnregister, idsToDelete,headers).then(response => {                             
           
            console.log(response);
            history.go(0) //Reload page
        }).catch(error => {                  
            console.log(error)
           
        });
    }

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      state: { selectedRowIds },
    } = useTable({ columns, data, 
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
    
    );
  
    // Render the UI for your table
    return (
        <>
        <BTable striped bordered hover {...getTableProps()} style={{ border: 'solid 1px blue' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}                  
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}                      
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </BTable>
      <BButton variant="danger" onClick={handleDelete} disabled={false}>Delete Selected</BButton> 
     </>
    );
} */

export default TableDashboard;