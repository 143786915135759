import React, {useState} from 'react';
import axios from 'axios';
import { Button, Modal, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

function FileUploadPage(props){
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [show, setShow] = useState(false);

  	const handleClose = () => setShow(false);
  	const handleShow = () => setShow(true);

	const apiEndpoint = props.apiEndpoint;
	const headers = props.headers;
	const streamName = props.streamName;
	const callBack = props.callback;

	const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
	};

	const handleSubmission = () => {
		const formData = new FormData()

		formData.append('file', selectedFile)
		formData.append('name',streamName)

        axios.post(apiEndpoint,formData,headers).then(response => {
            console.log(response);
			callBack()
          }).catch(error => {
            console.log(error);
          });  
		
	};
	

	return(
   <>
		<Button variant="primary" onClick={handleShow}>     
			<FontAwesomeIcon icon={faUpload} />  	
			
      	</Button>
		  <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Use only .svg images
		  <Form>
		  <Form.Control type="file" name="file" accept="image/svg+xml" onChange={changeHandler} />
		 	{isFilePicked ? (
				<div>
					<p>
					{selectedFile.name}<br/>					
					{selectedFile.size}bytes</p>					
				</div>
			) : (
				<p>Solament SVG. Select a file to show details</p>
			)}
			<Button variant="primary" onClick={handleSubmission}>Pujar</Button>
			
			</Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
	   		
	</>
	)
}

export default FileUploadPage