import React from 'react';
import { Formik } from 'formik';
import axios from 'axios';
//import Button from 'react-bootstrap/Button';
import {API_AUTH_DOMAIN} from '../settings.js';
import { getToken } from '../Utils/Common';
import BButton from 'react-bootstrap/Button';
import BForm from 'react-bootstrap/Form';
 
 const FormPackages = (props) => (
   <div className="border border- bg-light p-3 mb-2 rounded" color="primary">    
     <h5>{props.formTitle}</h5>
     <Formik
       initialValues={{ name: '', description: '',allTV:1,allCCTV:0,allMOVIES:1,allSERIES:1 }}
       validate={values => {
         const errors = {};
         if (!values.name) {
           errors.name = 'Required';
         } 
         return errors;
       }}
       
       onSubmit={(values, { setSubmitting }) => {
          const headers = {headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }}
          axios.post(API_AUTH_DOMAIN+'/private/iptvPackage/register', { name: values.name, description: values.description,allTV: values.allTV, allCCTV: values.allCCTV, allMOVIES:values.allMOVIES, allSERIES:values.allSERIES },headers).then(response => {      
            setSubmitting(true);            
            props.history.go(0)
          }).catch(error => {      
            setSubmitting(false);            
          });         
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <BForm onSubmit={handleSubmit} className="form-inline">
           <BForm.Group controlId="formName">           
              <BForm.Control 
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="form-control form-control-sm"
                placeholder="Name"
              />
              {errors.name && touched.name && errors.name}
           </BForm.Group>

           <BForm.Group controlId="formDescription">                      
              <BForm.Control
                type="text"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                className="ml-1 form-control form-control-sm"
                placeholder="description"
              />
              {errors.description && touched.description && errors.description}
           </BForm.Group>
           <BForm.Group controlId="formAll" className="ml-2">  
              <BForm.Check inline label="ALL TV" type="checkbox" id='allTV' />
              <BForm.Check inline label="ALL CCTV" type="checkbox" id='allCCTV' />
              <BForm.Check inline label="ALL MOVIES" type="checkbox" id='allMOVIES' />
              <BForm.Check inline label="ALL SERIES" type="checkbox" id='allSERIES' />
           </BForm.Group>

           <BButton type="submit" className="btn-sm ml-1 border-dark bg-dark" disabled={isSubmitting}>New</BButton>          
         </BForm>
       )}
     </Formik>
   </div>
 );
 
 export default FormPackages;
