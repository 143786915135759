import React from 'react';
import { getUser, removeUserSession } from '../Utils/Common';
import {Button,Navbar} from 'react-bootstrap';
import {ISP_NAME} from '../settings.js';

function NavbarDashboard(props) {
  const user = getUser();

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push('/login');
  }

  return (    
         <Navbar bg="dark" variant="dark" fixed="top" sticky="top" >
            <Navbar.Brand href="#" expand="lg">
                <img src={"/assets/img/favicons/favicon-"+ISP_NAME+"-32x32.png"} className="align-self-center mr-3" alt="..." />
                <img src={"/assets/logo-"+ISP_NAME+".svg"} height="32px" className="align-self-center mr-3" alt="..." />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="#sidebarMenu" aria-expanded="false" aria-label="Toggle navigation" />
           
            <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                    Signed in as: <a href="#login">{user.email}&nbsp;&nbsp;</a>
                </Navbar.Text>
            </Navbar.Collapse>
            <Button  className="justify-content-end" onClick={handleLogout} value="Logout">Logout</Button>            
        </Navbar>            
  );
}

export default NavbarDashboard;