import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import { Container, Row } from 'react-bootstrap';
import NavbarDashboard from './dashboard_subpages/NavbarDashboard';
import Sidebarmenu from './dashboard_subpages/Sidebarmenu';
import { API_AUTH_DOMAIN } from './settings.js';
import { getToken } from './Utils/Common';

import TableDashboard from './dashboard_subpages/TableDashboard';
import FormUsers from './dashboard_subpages/FormUsers';

function DashboardAdministradores(props) {
  /*  
  */
  const [users, setUsers] = useState([]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    /*const config = {
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }
    };
    const bodyParameters = {
      key: "value"
    };*/
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/admins', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });
      setUsers(result.data.users);
    })();
  }, []);

  const myStyle = (row, rowIndex) => {
    const style = {};    
    style.backgroundColor = 'white';    
    return style;
  };

  const columns = React.useMemo(
    () => [
      {
        text: 'Id',
        dataField: 'id', // accessor is the "key" in the data
        hidden:true,
      },
      {
        text: 'email',
        dataField: 'email',
      },
      {
        text: 'iptv package',
        dataField:'iptvPackage',
      },
      {
        text: 'Created at',
        dataField: 'createdAt',
      },
      {
        text: 'Updated at',
        dataField: 'updatedAt',
      },
      {        
        text: 'Admin',        
        dataField: 'isAdmin',
        type:'bool',
      },
    ],
    []
  )
  const crudEndpoints = {register:'/private/register',unregister:'/private/unregister'}

  return (

    <Container fluid>
      <NavbarDashboard history={props.history} />
      <Row>
        <Sidebarmenu />
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
          <h2 className="m-3">Administradores IPTV</h2>                   
          <FormUsers history={props.history} formTitle="Create new Administrator!" isAdmin="1" />
          <TableDashboard columns={columns} data={users} myStyle={ myStyle} history={props.history} rowSelection="multiple" crudEndpoints={crudEndpoints} />
        </main>

      </Row>
    </Container>

  );
}
export default DashboardAdministradores;