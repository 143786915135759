import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import {Container,Row} from 'react-bootstrap';
import NavbarDashboard from './dashboard_subpages/NavbarDashboard';
import Sidebarmenu from './dashboard_subpages/Sidebarmenu';
import FormPackages from './dashboard_subpages/FormPackages';
import TableDashboard from './dashboard_subpages/TableDashboard';
import { getToken } from './Utils/Common';
import { API_AUTH_DOMAIN } from './settings.js';
import { Type } from 'react-bootstrap-table2-editor';
import BForm from 'react-bootstrap/Form';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import "@fortawesome/fontawesome-free/css/fontawesome.css";

function DashboardPaquetes(props) {
    
  const [tvStreamOptions,setTvStreamOptions] = useState([]);
  const [tvStreamSelected,setTvStreamSelected] = useState([]);
  const [cctvStreamOptions,setCctvStreamOptions] = useState([]);
  const [cctvStreamSelected,setCctvStreamSelected] = useState([]);
  const [iptvPackages, setIptvPackages] = useState([]);
  //const [packageSelected, setPackageSelected] = useState([]);
  const [packageRowSelected, setPackageRowSelected] = useState([]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {   
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/iptvPackage/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });
      //debugger
      if (result.data.iptvPackages) 
          setIptvPackages(result.data.iptvPackages);
    })();
  }, []);
  // Get TV and CCTV streams 
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {   
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/stream/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });      
      debugger
      if (result.data && result.data.streams) {              
        let sTV = result.data.streams.filter(stream => stream.enabled && stream.type === 'TV').map(streamItem => ({value:streamItem.id,label:streamItem.name}));
        let sCCTV = result.data.streams.filter(stream => stream.enabled && stream.type === 'CCTV').map(streamItem => ({value:streamItem.id,label:streamItem.name}))
        setTvStreamOptions(sTV);      
        setCctvStreamOptions(sCCTV);      
      }
    })();
  }, []);
  
  
  const myStyle = (row, rowIndex) => {
    const style = {};    
    style.backgroundColor = 'white';    
    return style;
  };
  function allFormatter(cell, row) {    
    if (cell)
      return (<BForm.Check type="checkbox" defaultChecked />)      
    else{
      return (<BForm.Check type="checkbox" />)
    }
  }
  
  /*let handleOnSelectPackage = (row) => {    
      debugger
      setPackageSelected([row.id]);
      setPackageRowSelected(row);     
  }*/

  /*const selectOnlyOneRow = {
    mode: 'radio',    
    clickToSelect: true,
    clickToEdit: true,
    selected: packageSelected,
    onSelect: handleOnSelectPackage,    
  };*/
  let updateParentState = (row) => {
    setPackageRowSelected(row);
  }


  const columns = React.useMemo(
    () => {
      
      return [
        {
          text: 'Id',
          dataField: 'id', // accessor is the "key" in the data
          hidden: true,
        },
        {
          text: 'name',
          dataField: 'name', // accessor is the "key" in the data                        
          editable: false,
        },
        {
          text: 'description',
          dataField: 'description',
          sort: true,          
          editable: false,
        },
        {
          text: 'All TV',
          dataField: 'allTV',
          type: 'bool',
          formatter: allFormatter,
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }
        },
        {
          text: 'All CCTV',
          dataField: 'allCCTV',
          type: 'bool',
          formatter: allFormatter,
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }
        },
        {
          text: 'All Movies',
          dataField: 'allMOVIES',
          type: 'bool',
          formatter: allFormatter,
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }          
        },
        {
          text: 'All Series',
          dataField: 'allSERIES',
          type: 'bool',
          formatter: allFormatter,
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          }          
        },
      ]
    },
    []
  )
  const crudEndpoints = { register: '/private/iptvPackage/register',unregister: '/private/iptvPackage/unregister',  }
  return (
   
    <Container fluid>
        <NavbarDashboard history={props.history}/>
        <Row>
            <Sidebarmenu />
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">                
                <h2 className="m-2">Paquetes IPTV</h2>   
                <FormPackages history={props.history} formTitle="Create new Package!" />
                <TableDashboard columns={columns} myStyle={myStyle} data={iptvPackages} history={props.history} rowSelection="only_one" crudEndpoints={crudEndpoints} parentState={updateParentState} />             
                
                {
                  (packageRowSelected.name && !packageRowSelected.allTV) &&
                  <><h5>Select TV streams {packageRowSelected.name} package</h5>
                  <DualListBox
                    options={tvStreamOptions}
                    selected={tvStreamSelected}
                    onChange={setTvStreamSelected}
                  /></> 
                }

                {
                  (packageRowSelected.name && !packageRowSelected.allCCTV) &&
                  <><h5>Select CCTV streams for {packageRowSelected.name} package</h5>
                  <DualListBox
                    options={cctvStreamOptions}
                    selected={cctvStreamSelected}
                    onChange={setCctvStreamSelected}
                  /></>                
                }

            </main>
            
        </Row>
    </Container>
    
  );
}

export default DashboardPaquetes;