import React from 'react';
import { Formik } from 'formik';
import axios from 'axios';
//import Button from 'react-bootstrap/Button';
import {API_AUTH_DOMAIN} from '../settings.js';
import { getToken } from '../Utils/Common';
import BButton from 'react-bootstrap/Button';
import BForm from 'react-bootstrap/Form';

 const FormSettings = (props) => (
   <div className="border border- bg-light p-3 mb-3 rounded" color="primary">    
     <h5>{props.formTitle}</h5>
     <Formik
       initialValues={{ url: '', base64Auth: '' }}
       validate={values => {
         const errors = {};
         if (!values.url) {
           errors.url = 'Required';
         } else if (
           !/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/i.test(values.url)
         ) {
           errors.url = 'Invalid URL';
         }
         return errors;
       }}       

       onSubmit={(values, { setSubmitting }) => {
          const headers = {headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }}
          axios.post(API_AUTH_DOMAIN+'/private/server/register', { url: values.url, base64Auth: values.base64Auth },headers).then(response => {      
            setSubmitting(true);            
            debugger
            //props.history.push(props.url_reload);
            props.history.go(0)
          }).catch(error => {      
            setSubmitting(false);
            /*if (error.response.status === 401) setError(error.response.data.msg);
            else setError("Something went wrong. Please try again later.");*/
          });
         /*setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
           setSubmitting(false);
         }, 400);*/
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <BForm onSubmit={handleSubmit} className="form-inline">
           <BForm.Group controlId="formUrl">
              {/*<BForm.Label className="my-1 mr-2" for="url">
                URL server flussonic
       </BForm.Label> */}
           
              <BForm.Control
                type="url"
                name="url"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                placeholder="URL server flussonic"
                className="ml-1 form-control form-control-sm"
              /> 
              {errors.url && touched.url && errors.url}
          </BForm.Group>
          <BForm.Group controlId="formBase64Auth">
              {/* <BForm.Label className="my-1 ml-2 mr-2" htmlFor="base64Auth">
                  base64Auth(user:pass concatenated and encoded in base64)
      </BForm.Label> */}
              <BForm.Control
                type="password"
                name="base64Auth"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.base64Auth}
                placeholder="base64Auth(user:pass concatenated and encoded in base64)"
                className="ml-1 form-control form-control-sm"
              />
              {errors.base64Auth && touched.base64Auth && errors.base64Auth}
           </BForm.Group>
           <BButton type="submit" disabled={isSubmitting} className="btn-sm ml-2 border-dark bg-dark">
             New
           </BButton>
         </BForm>
       )}
     </Formik>
     
   </div>
 );
 
 export default FormSettings;
