import React, { useState,useEffect } from 'react';
import { getToken, removeUserSession, setUserSession } from './Utils/Common';

import axios from 'axios';
import './Login.css';
import Button from 'react-bootstrap/Button';
import {API_AUTH_DOMAIN,ISP_NAME} from './settings.js';

function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    
    axios.post(API_AUTH_DOMAIN+'/public/loginAdmin', { email: username.value, password: password.value }).then(response => {      
      setLoading(false);
      debugger
      if (response.data.user.isAdmin){
        setUserSession(response.data.token, response.data.user);
        props.history.push('/dashboard');
      }
    }).catch(error => {      
      setLoading(false);      
      if (error.response && error.response.status === 401) setError(error.response.data.msg);
      else setError("Something went wrong. Please try again later. Auth server seems down. "+error);
    });
  }
  
   
  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }
    
    axios.post(API_AUTH_DOMAIN+'/public/validate', { "token":token }).then(response => {      
      if (response.data.isvalid){
        setUserSession(token, JSON.parse(sessionStorage.getItem('user')));   
        props.history.push('/dashboard')     
      }else{
        removeUserSession();  
      }
      setAuthLoading(false);
    }).catch(error => {
      removeUserSession();
      setAuthLoading(false);
    });
  }, [props.history]);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>
  }

  return (
    <div className="login text-center">
     <form className="form-signin">
            <img className="mb-4" src={"/assets/brand/logo-"+ ISP_NAME+ ".png"} alt="" width="72" height="72" />
            <h1 className="h3 mb-3 font-weight-normal">{ISP_NAME} IPTV middleware</h1>
            <label htmlFor="inputEmail" className="sr-only">Email </label>
            <input type="email" id="inputEmail" {...username} className="form-control" placeholder="Email address" required="" autoFocus="" />
            <label htmlFor="inputPassword" className="sr-only">Password</label>
            
            <input type="password" id="inputPassword" {...password}  className="form-control" placeholder="Password" required="" />
            <div className="checkbox mb-3">
                <label>
                <input type="checkbox" value="remember-me"/> Recuérdame
                </label>
            </div>
            {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
            
            <Button type="submit" variant="primary" block value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading}>Login</Button> 
            <p className="mt-5 mb-3 text-muted">© 2021</p> 
        </form>
    </div>

  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;