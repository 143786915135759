import React, { useState, useEffect } from 'react';
//import { getUser, removeUserSession } from './Utils/Common';
import './Dashboard.css';
import { Button, Container, Row ,Image} from 'react-bootstrap';
import NavbarDashboard from './dashboard_subpages/NavbarDashboard';
import Sidebarmenu from './dashboard_subpages/Sidebarmenu';
import TableDashboard from './dashboard_subpages/TableDashboard';
import FileUploadPage from './components/FileUploadPage';
import { getToken } from './Utils/Common';
import { API_AUTH_DOMAIN } from './settings.js';
import axios from 'axios';
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';

//Used to read safely a nested JSON property that could fail across chain due to undefined value
function getSafe(fn, defaultVal) {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
}

function flussonicApiRequest() {
  //BUG: Not joining at the moment diferent possible servers output. Only works with a single server

  return new Promise((myResolve, myReject) => {
    try {  
      (async () => {
        const result = await axios.get(API_AUTH_DOMAIN + '/private/server/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });

        let streams;        
        let servers = result.data.servers;
        servers.forEach((serverItem) => {
          (async () => {
            //streams = await axios.get(serverItem.url + '/flussonic/api/media', { headers: { 'content-type': 'application/json', Authorization: 'Basic ' + serverItem.base64Auth } });            
            streams = await axios.get(serverItem.url + '/flussonic/api/streams', { headers: { 'content-type': 'application/json', Authorization: 'Basic ' + serverItem.base64Auth } });            
            debugger
            myResolve({streams:streams.data.streams,serverURL:serverItem.url});
          })();
        })
      })();
    } catch (error) {
      myReject("Error " + error);
    }
  });
}

function Dashboard(props) {
  const [isSyncing, setSyncing] = useState(false);
  const [isUpdating,setUpdating] = useState(false);

  useEffect(() => {
    if (isSyncing) {
      flussonicApiRequest().then((streamsObject) => {
        setSyncing(false);
      
        streamsObject.streams.forEach((streamItem) => {
          
          const headers = { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } }
          
          axios.post(API_AUTH_DOMAIN + '/private/stream/register', {
            name: streamItem.name,
            img: streamItem.name,            
            title: getSafe(()=>streamItem.options.title,streamItem.name),            
            description: getSafe(()=>streamItem.options.meta.comment,''),
            enabled: streamItem.alive,
            serverURL:streamsObject.serverURL

          }, headers).then(response => {
            console.log(response);
          }).catch(error => {
            console.log(error);
          });
        })
        forceUpdating();
      });
    }
  }, [isSyncing]);

  
  const handleClick = () => setSyncing(true);
  const forceUpdating = () => setUpdating(true);
  
  const [streams, setStreams] = useState([]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/stream/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });
      setUpdating(false)
      if (result.data && result.data.streams)
        debugger
        setStreams(result.data.streams.sort((a,b)=>{
          let da = new Date(a.updatedAt),
          db = new Date(b.updatedAt);
          return db - da;          
        }));
    })();
  }, [isUpdating]);

  /*function imageFormatter(cell, row) {    
    return <img key={cell} onError={addDefaultSrc} alt={'Logo ' + cell} width="48px" height="48px" src={'assets/logos/' + cell + ".svg"} />    
  }*/
  
  function uploadFormatter(cell, row) {
    const headers = {headers: { 'content-type': 'multipart/form-data', Authorization: `Bearer ${getToken()}` }}    
    return (
    <>
     <Image key={cell} onError={addDefaultSrc} alt={'Logo ' + cell} width="48px" height="48px" src={'assets/logos/' + cell + ".svg"} /> 
     <FileUploadPage callback={forceUpdating} streamName={row.name} headers={headers} apiEndpoint={API_AUTH_DOMAIN + '/private/stream/uploadLogo'}/>
     
     </>
    )
   }

  function addDefaultSrc(ev){
    ev.target.src = 'assets/logos/undefined.svg'
    
  }
  const myStyle = (row, rowIndex) => {
    const style = {};
    if (row.enabled) {
      style.backgroundColor = 'white';
    } else {
      style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
    }
    return style;
  };

  const columns = React.useMemo(
    () => {
      const categoryOptions = {
        0: 'Deportes',
        1: 'Noticias',
        2: 'Local',
        4: 'Privado',
        5: 'Cine/Series',
        6: 'Documental',
        7: 'Temático',
        8: 'Autonómico',
        9: 'Internacional',
        10: 'Infantil',
        11: 'Generalista'
      };

      return [
        {
          text: 'Id',
          dataField: 'id', // accessor is the "key" in the data
          hidden: true,
        },
        
        {
          text: 'Logo',
          dataField: 'img', // accessor is the "key" in the data              
          formatter: uploadFormatter,
          editable: false,
        },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          filter: textFilter(),
          editable: false,
        },
        {
          text: 'Title',
          dataField: 'title',
          editable: false,
        },
        {
          text: 'Description',
          dataField: 'description',
          editable: false,
        },
        {
          text: 'Type',
          dataField: 'type',
          editor: {
            type: Type.SELECT,
            options: [{
              value: 'TV',
              label: 'TV'
            }, {
              value: 'CCTV',
              label: 'CCTV'
            }, {
                value: 'DISABLED',
                label: 'DISABLED'
              }
            ]
          }
        },
        {
          text: 'Category',
          dataField: 'category',
          filter: selectFilter({
            placeholder: 'ALL',  // custom the input placehol
            options: categoryOptions
          }),
          editor:{
            type: Type.SELECT,
            options: Object.values(categoryOptions).map((valor,index)=>{ return {'value':valor,'label':valor}})
          }
        },
        {
          text: 'Ena. Flussonic',
          dataField: 'enabled',
          type: 'bool',
          editable: false,
        },
        {
          text: 'EPG Id',
          dataField: 'epgId',
          type: 'string',
          editable: true,
        },
      ]
    },
    []
  )
  const crudEndpoints = { register: '/private/stream/register',unregister: '/private/stream/unregister' }

  return (

    <Container fluid>
      <NavbarDashboard history={props.history} />
      <Row>
        <Sidebarmenu />
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">
          <h2 className="m-2">Canales IPTV</h2>
          <Button className="m-2"
            variant="primary"
            disabled={isSyncing}
            onClick={!isSyncing ? handleClick : null}>
            {isSyncing ? 'Syncing …' : 'Sync Servidores'}
          </Button>
          <TableDashboard columns={columns} myStyle={myStyle} data={streams} history={props.history}  rowSelection="multiple" crudEndpoints={crudEndpoints} />
        </main>

      </Row>
    </Container>

  );
}
/*<nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        
        
      </nav>*/

export default Dashboard;