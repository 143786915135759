import React from 'react';
import { Formik } from 'formik';
import axios from 'axios';
//import Button from 'react-bootstrap/Button';
import {API_AUTH_DOMAIN} from '../settings.js';
import { getToken } from '../Utils/Common';
import BButton from 'react-bootstrap/Button';
import BForm from 'react-bootstrap/Form';
 
 const FormUsers = (props) => (
   <div className="border border- bg-light p-3 mb-2 rounded" color="primary">    
     <h5>{props.formTitle}</h5>
     <Formik
       initialValues={{ email: '', password: '' }}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       /*
       useEffect(() => {
    const config = {
      headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }
    };
    const bodyParameters = {
      key: "value"
    };
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/users', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });
      setUsers(result.data.users);
    })();
  }, []);
       */

       onSubmit={(values, { setSubmitting }) => {
          const headers = {headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }}
          axios.post(API_AUTH_DOMAIN+'/private/register', { email: values.email, password: values.password,password2: values.password,isAdmin:props.isAdmin },headers).then(response => {      
            setSubmitting(true);            
            debugger
            //props.history.push(props.url_reload);
            props.history.go(0)
          }).catch(error => {      
            setSubmitting(false);
            /*if (error.response.status === 401) setError(error.response.data.msg);
            else setError("Something went wrong. Please try again later.");*/
          });
         /*setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
           setSubmitting(false);
         }, 400);*/
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <BForm onSubmit={handleSubmit} className="form-inline">
           <BForm.Group controlId="formEmail">
            {/* <BForm.Label htmlFor="email">
              Email:
       </BForm.Label> */}
          
           <BForm.Control 
             type="email"
             name="email"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
             className="form-control form-control-sm"
             placeholder="Email"
           />
           {errors.email && touched.email && errors.email}
           </BForm.Group>
           <BForm.Group controlId="formPassword">
           {/* <BForm.Label htmlFor="email" >
              Password: 
      </BForm.Label> */}
            
           <BForm.Control
             type="password"
             name="password"
             onChange={handleChange}
             onBlur={handleBlur}
             value={values.password}
             className="ml-1 form-control form-control-sm"
             placeholder="password"
           />
           {errors.password && touched.password && errors.password}
           </BForm.Group>

           <BButton type="submit" className="btn-sm ml-1 border-dark bg-dark" disabled={isSubmitting}>New</BButton>          
         </BForm>
       )}
     </Formik>
   </div>
 );
 
 export default FormUsers;
