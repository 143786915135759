
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Dashboard.css';
import {Container,Row} from 'react-bootstrap';
import NavbarDashboard from './dashboard_subpages/NavbarDashboard';
import Sidebarmenu from './dashboard_subpages/Sidebarmenu';
import FormSettings from './dashboard_subpages/FormSettings';
import TableDashboard from './dashboard_subpages/TableDashboard';
import { getToken } from './Utils/Common';
import { API_AUTH_DOMAIN } from './settings.js';

function DashboardSettings(props) {
  const [servers, setServers] = useState([]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {   
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/server/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });
      setServers(result.data.servers);
    })();
  }, []);
  
  const myStyle = (row, rowIndex) => {
    const style = {};    
    style.backgroundColor = 'white';    
    return style;
  };

  const columns = React.useMemo(
    () => [
      {
        text: 'Id',
        dataField: 'id', // accessor is the "key" in the data
        hidden:true,
      },
      {
        text: 'flussonic URL',
        dataField: 'url',
      },
      {
        text: 'base64Auth',
        dataField: 'base64Auth',
      },
      {
        text: 'Created at',
        dataField: 'createdAt',
      },
      {
        text: 'Updated at',
        dataField: 'updatedAt',
      },      
    ],
    []
  )

  const crudEndpoints = {register:'/private/server/register',unregister:'/private/server/unregister'}

  return (
   
    <Container fluid>
        <NavbarDashboard history={props.history}/>
        <Row>
            <Sidebarmenu />
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">                
                <h2 className="m-3">Settings IPTV</h2>        
                  
                <FormSettings history={props.history} formTitle="New flussonic server!"/>
                <TableDashboard columns={columns} data={servers} myStyle={myStyle} rowSelection="multiple" crudEndpoints={crudEndpoints} />        
            </main>
            
        </Row>
    </Container>
    
  );
}
/*<nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        
        
      </nav>*/

export default DashboardSettings;