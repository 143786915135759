import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import Login from './Login';
import Dashboard from './Dashboard';
import DashboardVideoClub from './DashboardVideoClub';
import DashboardSeries from './DashboardSeries';
import DashboardPaquetes from './DashboardPaquetes';
import DashboardUsuarios from './DashboardUsuarios';
import DashboardAdministradores from './DashboardAdministradores';
import DashboardSettings from './DashboardSettings';
import PrivateRoute from './Utils/PrivateRoute';
import PublicRoute from './Utils/PublicRoute';

function App() {
    
  return (
    <div className="App">
      <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <PublicRoute path="/login" component={Login} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/dashboardVideoClub" component={DashboardVideoClub} />
              <PrivateRoute path="/dashboardSeries" component={DashboardSeries} />
              <PrivateRoute path="/dashboardPaquetes" component={DashboardPaquetes} />              
              <PrivateRoute path="/dashboardUsuarios" component={DashboardUsuarios} />
              <PrivateRoute path="/dashboardAdministradores" component={DashboardAdministradores} />
              <PrivateRoute path="/dashboardSettings" component={DashboardSettings} />
            </Switch>
       </BrowserRouter>
    </div>
  );
}

export default App;