import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import {Button, Container,Row} from 'react-bootstrap';
import NavbarDashboard from './dashboard_subpages/NavbarDashboard';
import Sidebarmenu from './dashboard_subpages/Sidebarmenu';
import axios from 'axios';
import { API_AUTH_DOMAIN } from './settings.js';
import { getToken } from './Utils/Common';
import TableDashboard from './dashboard_subpages/TableDashboard';
import { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';
import BForm from 'react-bootstrap/Form';

function flussonicApiRequest() {

  //TO FIX: Not joining at the moment diferent possible servers output. Only works with a single server
  return new Promise((myResolve, myReject) => {
    try {  
      (async () => {
        const result = await axios.get(API_AUTH_DOMAIN + '/private/server/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });

        let movies;        
        let servers = result.data.servers;
        servers.forEach((serverItem) => {          
          (async () => {           
            movies = await axios.get(serverItem.url + '/flussonic/api/list_files?prefix=Movies', { headers: { 'content-type': 'application/json', Authorization: 'Basic ' + serverItem.base64Auth } });
            myResolve(movies.data.files);
          })();
        })
      })();
    } catch (error) {
      myReject("Error " + error);
    }
  });
}

function DashboardVideoClub(props) {
  const [isSyncing, setSyncing] = useState(false);

  useEffect(() => {
    if (isSyncing) {
      flussonicApiRequest().then((movies) => {
        setSyncing(false);
        movies.forEach((movieItem) => {
          debugger
          const headers = { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } }
          axios.post(API_AUTH_DOMAIN + '/private/movie/register', {
            name: movieItem.name,            
            //title: movieItem.name,            
            prefix: movieItem.prefix+'/'+movieItem.name+'/index.m3u8',        
            enabled: '1',
            type: 'MOVIES',
          }, headers).then(response => {
            console.log(response);
          }).catch(error => {
            console.log(error);
          });
        })
      });
    }
  }, [isSyncing]);

  const handleClick = () => setSyncing(true);

  const [movies, setMovies] = useState([]);
  // Using useEffect to call the API once mounted and set the data
  useEffect(() => {
    (async () => {
      const result = await axios.get(API_AUTH_DOMAIN + '/private/movie/all', { headers: { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` } });

      if (result.data && result.data.movies)        
        setMovies(result.data.movies);
    })();
  }, []);

  function imageFormatter(cell, row) {    
    return <img key={cell} alt={'Logo ' + cell} width="128px" height="168px" src={cell} />;
  }

  const myStyle = (row, rowIndex) => {
    const style = {};
    if (row.enabled) {
      style.backgroundColor = 'white';
    } else {
      style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
    }
    return style;
  };

  function allFormatter(cell, row) {    
    if (cell)
      return (<BForm.Check type="checkbox" defaultChecked />)      
    else{
      return (<BForm.Check type="checkbox" />)
    }
  }
  const columns = React.useMemo(
    () => {
      
      return [
        {
          text: 'Id',
          dataField: 'id', // accessor is the "key" in the data
          hidden: true,
        },
        {
          text: 'Caratula',
          dataField: 'posterURL', // accessor is the "key" in the data              
          formatter: imageFormatter,
          editable: true,
        },
        {
          text: 'Name',
          dataField: 'name',
          sort: true,
          filter: textFilter(),
          editable: false,
        },
        {
          text: 'Title',
          dataField: 'title',
          filter: textFilter(),
          editable: true,
        },
        {
          text: 'Description',
          dataField: 'description',
          filter: textFilter(),
          editable: true,
        },        
        {
          text: 'Category',
          dataField: 'categoryTags',
          filter: textFilter(),
          editable: true,
        },
        {
          text: 'Enabled',
          dataField: 'enabled',
          type: 'bool',
          formatter: allFormatter,
          editor: {
            type: Type.CHECKBOX,
            value: 'true:false'
          },      
          //editable: true,
        },
      ]
    },
    []
  )
  const crudEndpoints = { register: '/private/movie/register',unregister: '/private/movie/unregister' }


  return (
   
    <Container fluid>
        <NavbarDashboard history={props.history}/>
        <Row>
            <Sidebarmenu />
            <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4">                
                <h2 className="m-2">Video Club IPTV</h2>         
                <Button className="m-2"
                  variant="primary"
                  disabled={isSyncing}
                  onClick={!isSyncing ? handleClick : null}>
                  {isSyncing ? 'Syncing …' : 'Sync Servidores'}
                </Button>
                <TableDashboard columns={columns} myStyle={myStyle} data={movies} history={props.history}  rowSelection="multiple" crudEndpoints={crudEndpoints} />       
            </main>
            
        </Row>
    </Container>
    
  );
}

export default DashboardVideoClub;